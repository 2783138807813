/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.508 1.335l-.856-.857c-.636-.637-1.746-.637-2.382 0L7.163 1.586l-.003.003-.003.004L.567 8.19a.472.472 0 00-.134.268l-.428 3a.475.475 0 00.535.537l2.996-.428a.473.473 0 00.267-.134l6.59-6.598.003-.004.003-.003 1.108-1.108c.318-.319.493-.742.493-1.192 0-.45-.175-.875-.492-1.193zM3.246 10.65l-2.214.317.317-2.218 6.146-6.153 1.898 1.9-6.147 6.154zm7.592-7.601l-.776.776-1.897-1.9.775-.776a.75.75 0 011.042 0l.856.857c.14.14.215.325.215.522a.73.73 0 01-.215.52z" _fill="#9DA4C0"/>'
  }
})
